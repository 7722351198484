<template>
    <div>
        <v-layout row wrap :align-center="!wideLayout" :justify-center="!wideLayout" :fill-height="!wideLayout">
            <v-flex xs12 sm12 :md12="wideLayout" :lg12="wideLayout" :md10="!wideLayout" :lg10="!wideLayout" :xl8="!wideLayout">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="5"
                    class="elevation-1"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    itemsPerPageOptions: [5,10,15,25,50, 100]
                    }">

                    <template v-slot:top>
                        <v-toolbar class="elevation-1" color="white">
                            <v-toolbar-title class="font-weight-regular body-1">Priložene datoteke</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-flex xs4 md4 lg4 v-show="breakPoint">
                                <v-text-field class="pt-4"  label="Išči..." @keyup.esc="search = ''" v-model="search" append-icon="mdi-magnify" single-line clearable @click:clear="doSearch()"></v-text-field>
                            </v-flex>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            v-if="btnRefresh"
                            ></v-divider>
                            <v-tooltip v-if="btnRefresh" top>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="refreshDataTable" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                        <v-icon>mdi-sync</v-icon>
                                    </v-btn>
                                </template>
                                <span>Osveži podatke</span>
                            </v-tooltip>
                        </v-toolbar>
                        <br>
                    </template>
                    <template v-slot:item.action="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_edit"
                                color="indigo"
                                small
                                class="mr-2"
                                @click="viewItem(item)"
                                v-on="on"
                            >
                                mdi-magnify-plus-outline
                            </v-icon>
                        </template>
                        <span>Prikaži</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_delete"
                                color="success"
                                small
                                class="mr-2"
                                @click="downloadItem(item)"
                                v-on="on"
                            >
                                mdi-file-download-outline
                            </v-icon>
                        </template>
                        <span>Prenesi</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_delete"
                                color="error"
                                small
                                class="mr-2"
                                @click="deleteItem(item)"
                                v-on="on"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Izbriši</span>
                    </v-tooltip>
                </template>
                <template v-slot:progress>
                    <v-progress-linear absolute  indeterminate :height="progressHeight()"></v-progress-linear>
                </template>
                <template slot="no-data">
                    <div class="no_data">{{ (this.loading) ? "Obdelava podatkov je v teku ..." : "Ni podatkov za prikaz." }}</div>
                </template>
                <template slot="no-results">
                    <div class="no_data">Ni podatkov za prikaz.</div>
                </template>
                <template slot="loading">
                    <div class="no_data">Obdelava podatkov je v teku ...</div>
                </template>
                <template v-slot:item.file_type="{ item }">
                    <v-chip label class="" v-if="item != null && item.file_type !== null && item.file_type !=='null' && item.file_type !== ''" :color="getFileTypeColor(item.file_type)" dark small><v-icon text left small>{{ getFileTypeIcon(item.file_type) }}</v-icon>{{ getFileTypeText(item.file_type) }}</v-chip>
                    <span v-else>{{ getFileTypeText(item.file_type) }}</span>
                </template>
                </v-data-table>
            </v-flex>
        </v-layout>
        
        <!-- delete dialog -->
        <v-dialog v-model="deleteDialog" persistent max-width="540">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <span>
                        <v-icon text>mdi-delete</v-icon>
                        {{ deleteItemText() }}
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click.native="dialogCancel()">Prekliči</v-btn>
                    <v-btn text :loading="loading" color="success" @click.native="dialogConfirm()">Nadaljuj</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { post, get, destroy, API_ENDPOINT } from '@/helpers/api'

export default {
    name: 'application-files-data-table',
    props: ['applicationUuid','boxedLayout', 'settings'],

    data: () => ({
        headers: [
            { text: 'Zap. št.', value: 'app_increment' },
            { text: 'Naziv', value: 'name' },
            { text: 'Tip datoteke', value: 'file_type' },
            { text: 'Velikost', value: 'file_size_readable' },
            { text: 'Upravljanje', value: 'action', align:"right", sortable: false },
        ],
        items: [
            /*{
                app_increment: 1,
                name: 'nIXNB7Xmtq0cJOP914bC2Wu5JI3GaTTb2MQtb2qE.jpeg',
                file_type: 'image/jpeg',
                file_size_readable: '201.9KB'
            },
            {
                app_increment: 2,
                name: 'nIXNSDFSDAFSADFASDFSDAFASDFASDFASDFASDFAS.jpeg',
                file_type: 'image/jpeg',
                file_size_readable: '501.9KB'
            },*/
            
        ],

        imageItems: [

        ],
        index: 0,

        options: {},
        loading: false,
        search: '',
        interval : null,
        deleteDialog: false,
        itemToDelete: null,
        dialogConfirmation: false,
        searchTimer: null
    }),

    watch: {
        options: {
            handler () {
                //this.items = [];
                this.totalItems = this.items.length;
            },
            deep: true,
        },

        search(value) {
        },

        searchFromStore(newValue) {
            this.search = newValue
            this.doSearch()
        }
    },

    computed: {
        wideLayout() {
           if(this.boxedLayout !== undefined) {
               if(this.boxedLayout === true) {
                   return false
               }

               return true
           }

           return true
       },

       breakPoint() {
           if(this.$vuetify.breakpoint.mdAndUp) {
                return true;
            }

             return false;
       },

       btnRefresh() {
           return true
       },

       searchFromStore: {
           get() {
               return this.$store.getters.search
           },

           set(value) {
               this.$store.commit('SET_SEARCH', this.search)
           }
       }
    },

    methods: {
        progressHeight() {
           return 3
       },

        getFiles(uuid) {
            var vm = this
            vm.loading = true
            get(API_ENDPOINT + 'v1/admin/applications/' + uuid + '/files')
            .then(response => {
                //window.console.log(response)
                vm.items = []
                vm.imageItems = []
                response.data.forEach(item => {
                    vm.items.push(item)
                    if(item.file_type != 'application/pdf') {
                        vm.imageItems.push(item)
                    }
                })
            })
            .catch(error => {
                window.console.error("### ERROR ApplicationFilesDataTable ###")
                window.console.log(error)
            })
            .then(() => {
                vm.loading = false
            })
        },

        refreshDataTable() {
            this.getFiles(this.applicationUuid);
        },

        viewItem(item) {
            Object.assign(document.createElement('a'), { target: '_blank', href: item.view}).click();
        },

        downloadItem(item) {
            Object.assign(document.createElement('a'), {href: item.download}).click();
        },

        deleteItem(item) {
            this.itemToDelete = item;
            this.deleteDialog = true
        },

        getFileTypeText(fileType) {
            if(fileType == 'application/pdf') {
                return "PDF"
            }

            return "Slika"
        },

        getFileTypeIcon(fileType) {
            if(fileType == 'application/pdf') {
                return "mdi-adobe-acrobat"
            }

            return "mdi-file-image-outline"
        },

        getFileTypeColor(fileType) {
            if(fileType == 'application/pdf') {
                return "red"
            }

            return "blue"
        },

        deleteItemText() {
            if(this.itemToDelete != null) {
                return "Ste prepričani, da želite odstraniti prilogo z zaporedno številko  #" + this.itemToDelete.app_increment + " ?";
            }

            return ""
        },

        dialogConfirm() {
            var vm = this
            vm.loading = true
            var file_app_increment = vm.itemToDelete.app_increment;

            post(API_ENDPOINT + 'v1/files/' + vm.itemToDelete.id + '/delete')
            .then(response => {
                //window.console.log(response)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TEXT', "Datoteka z zporedno številko #" + file_app_increment + " je bila uspešno odstranjena.")
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
            .catch(error => {
                window.console.error(error)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri odstranjevanju datoteke z zaporedno številko #" + file_app_increment + " je prišlo do napake.")
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
            .then(() => {
                vm.loading = false
                vm.deleteDialog = false
                vm.itemToDelete = null
                vm.getFiles(vm.applicationUuid)
            })

            //window.console.log(this.itemToDelete)

        },

        dialogCancel() {
            this.dialogConfirmation = false
            this.deleteDialog = false
            this.itemToDelete = null
        },

        doSearch() {
           let vm = this
           if(this.searchTimer) {
               clearTimeout(this.searchTimer)
               this.searchTimer = null
           }

           this.searchTimer = setTimeout(() => {
               vm.getDataFromApi()
                .then(data => {
                   vm.items = data.items
                   vm.totalItems = data.totalItems
               })
               .catch(error => {
                   vm.items = [];
                   vm.totalItems = 0;
                   window.console.log(error)
               })
           }, 600)
       }
    },

    created() {

    },

    mounted() {
        var vm = this
        setTimeout(() =>{
            vm.getFiles(vm.applicationUuid);
        }, 700)

        this.interval = setInterval(() => {
            vm.getFiles(vm.applicationUuid)
        }, 20000)
    }, 

    destroyed() {
        clearInterval(this.interval)
    }
}

</script>

<style scoped>
    .v-data-table__empty-wrapper {
        text-align:center !important;
        color: #000 !important;
    }

    .no_data {
        text-align:center !important;
    }

    #style-11::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
    
    #style-11::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
        border: 1px solid #ccc;
    }

    #style-11::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(left, #fff, #e4e4e4);
        border: 1px solid #aaa;
    }

    #style-11::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }

    #style-11::-webkit-scrollbar-thumb:active {
        background: linear-gradient(left, #22ADD4, #1E98BA);
    }

    .toolbar_btn_fab {
        margin-right:10px;
    }

    #dt_btn_edit {
        font-size:18px !important;
    }

    #dt_btn_delete {
        font-size:18px !important;
    }

    .no_data {
        color: #000 !important;
    }

    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }
</style>